<template>
  <iframe ref="iframe" class="reward" :src="rewardUrl"></iframe>
</template>

<script>
export default {
  name: 'Reward',
  props: {
    lang: {
      type: String
    },
    sid: {
      type: Number
    },
    rid: {
      type: String
    },
  },
  data () {
    return {
      reward: ''
    }
  },
  computed: {
    rewardUrl () {
      return `${process.env.VUE_APP_ADMIN_API}/api/reward-render?sid=${this.sid}&rid=${this.rid}&lang=${this.lang}`
    }
  },
  async mounted () {
    const frame = this.$refs.iframe
    document.documentElement.style['overflow-y'] = 'hidden'
    frame.contentDocument.documentElement.innerHTML = this.reward
    frame.contentDocument.documentElement.style['overflow-x'] = 'hidden'
  },
  beforeDestroy () {
    document.documentElement.style['overflow-y'] = ''
  }
}
</script>

<style scoped>
.reward {
  z-index: 10;
  width: 100vw;
  height: 100vh;
}
</style>
